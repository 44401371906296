













import { defineComponent } from '@vue/composition-api'
import Title from '@/components/atoms/Title/Title.vue'

export default defineComponent({
  name: 'TitleGroup',
  props: {
    title: { type: String, required: true },
    subTitle: { type: String },
    description: { type: String }
  },
  components: {
    Title
  }
})
