







































































import { defineComponent, inject, ref, computed } from '@vue/composition-api';
import AuthenticationService, { AuthenticationServiceToken } from '@/services/Authentication/AuthenticationService';
import i18n from '@/i18n/i18n';
import BackLink from '@/components/molecules/BackLink/BackLink.vue';
import TitleGroup from '@/components/molecules/TitleGroup/TitleGroup.vue';
import useForm, { required, match } from '@/hooks/useForm';
import names from '@/router/routes/names';
import { Form, Button, AlertMessage, InputField } from '@sennder/plankton';

export default defineComponent({
  name: 'ResetPassword',
  components: {
    BackLink,
    TitleGroup,
    Form,
    Button,
    AlertMessage,
    InputField,
  },
  setup(_, { root: { $route } }) {

    const service = inject<AuthenticationService>(AuthenticationServiceToken);

    const password = ref<string>('');
    const confirmPassword = ref<string>('');
    const token = ref<string>($route.query.token as string);
    const uid = ref<string>($route.query.uid as string);

    const formSent = ref<boolean>(false);
    const errors = ref<string[]>([]);

    const title = computed(() => {
      if (formSent.value) {
        return i18n.t('resetPassword.title.formSent');
      }
      return i18n.t('label.newPassword');
    });

    const description = computed(() => {
      if (formSent.value) {
        return i18n.t('resetPassword.description.formSent');
      }
      return i18n.t('resetPassword.description');
    });

    const successCallback = async () => {
      try {
        await service?.setNewPassword({
          token: token.value,
          uid: uid.value,
          password: password.value,
          confirmPassword: confirmPassword.value,
        });
        formSent.value = true;
      } catch (err) {
        if (typeof (err) === 'string') {
          errors.value = [err];
        } else {
          errors.value = err;
        }

      }
    };

    const { handleSubmit } = useForm(successCallback);

    const validators = {
      password: [required(i18n.t('label.password') as string)],
      confirmPassword: [
        required(i18n.t('label.confirmPassword') as string),
        match(i18n.t('label.confirmPassword') as string, i18n.t('label.confirmPassword') as string, password),
      ],
    };

    return {
      title,
      description,
      password,
      confirmPassword,
      validators,
      errors,
      handleSubmit,
      formSent,
      loginRoute: { name: names.login },
    };

  },
});

