import i18n from '@/i18n/i18n';
import { Ref } from '@vue/composition-api';

interface PlanktonSubmitEvent {
  hasErrors: boolean;
}

interface PlanktonFieldValidator {
  isInvalid(val: string | null): boolean;
  errorMessage: string;
}

// eslint-disable-next-line
type ValidatorBuilder = (fieldName: string, ...args: any[]) => PlanktonFieldValidator;

export const required: ValidatorBuilder = (name: string) => ({
  isInvalid: (val) => !val,
  errorMessage: i18n.t('form.error.required', [name]) as string,
});

export const email: ValidatorBuilder = (name: string) => ({
  // eslint-disable-next-line
  isInvalid: (val) => !!val && !val?.match(/(?:[a-z0-9A-Z!#$%&'*\/=?^_`{|}~-]+(?:\.[a-z0-9A-Z!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9A-Z](?:[a-z0-9A-Z-]*[a-z0-9A-Z])?\.)+[a-z0-9A-Z](?:[a-z0-9A-Z-]*[a-z0-9A-Z])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9A-Z-]*[a-z0-9A-Z]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/),
  errorMessage: i18n.t('form.error.email', [name]) as string,
});

export const phone: ValidatorBuilder = (name: string) => ({
  // eslint-disable-next-line
  isInvalid: (val) => !!val && !val?.match(/^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-\/\s.]|\d)+$/),
  errorMessage: i18n.t('form.error.phone', [name]) as string,
});

export const minLength: ValidatorBuilder = (name: string, min: number) => ({
  isInvalid: (val) => (val?.length ?? -1) < min,
  errorMessage: i18n.t('form.error.min.length', [name, min]) as string,
});

export const match: ValidatorBuilder = (name: string, matchName: string, passwordReference: Ref<string>) => ({
  isInvalid: (val) => val !== passwordReference.value,
  errorMessage: i18n.t('form.error.match', [name, matchName]) as string,
});

export default (callback: Function) => {
  const handleSubmit = (e: PlanktonSubmitEvent) => {
    if (e.hasErrors) {
      return;
    }

    callback();
  };

  return {
    handleSubmit,
  };
};
