













import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'textLink',
  props: {
    to: { type: [Object, String], required: true }
  },
  setup(props) {
    return {
      external: computed<boolean>(() => typeof props.to === 'string')
    }
  }
})
