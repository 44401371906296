









import { defineComponent } from '@vue/composition-api'
import TextLink from '@/components/atoms/TextLink/TextLink.vue'
import SvgIcon from '@/components/atoms/SvgIcon/SvgIcon.vue'

export default defineComponent({
  name: 'BackLink',
  components: {
    TextLink,
    SvgIcon
  },
  props: {
    to: { type: [Object, String], required: true }
  }
})
